import {
  fetchQueueStatus,
  initWebMessenger,
  subscribeToEvent,
  toggleLauncherVisibility,
} from '@nuuday/web-messenger';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { isUserAgent } from '../utils/user-agent';

// paths that should show the web messenger
const whiteListedPaths = [
  '/betalingsmetode',
  '/regninger',
  '/indstillinger',
  '/email',
  '/indbetaling',
  '/tv',
  '/bredbaand',
  '/mobil',
  '/fastnet',
  '/mobiltbredbaand',
];

// for subpages of the above paths that should not show the web messenger
const blackListedPaths = [['/mobil', '/abonnement']];

export function useWebMessenger() {
  const { pathname } = useLocation();
  const [isReady, setIsReady] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const queue = 'YS_Chat_Mit_Yousee';
  const shouldBeHidden = isUserAgent(['youseeSelfcareApp', 'netcrackerApp']);

  useEffect(() => {
    if (shouldBeHidden) {
      return;
    }

    const environment = process.env.NODE_ENV === 'production' ? 'production' : 'staging';

    const doAsync = async () => {
      const queueStatus = await fetchQueueStatus({ queue, environment });

      if (queueStatus === 'closed') {
        return;
      }
      await initWebMessenger({
        environment,
        initialQueue: queue,
      });

      subscribeToEvent('Messenger.opened', () => setIsOpen(true));
      setIsReady(true);
    };

    doAsync();
  }, [shouldBeHidden]);

  useEffect(() => {
    const isValidPath =
      whiteListedPaths.some((path) => pathname.startsWith(path)) &&
      !blackListedPaths.some((path) => {
        return path.every((pathSegment) => pathname.includes(pathSegment));
      });

    if (!isReady || shouldBeHidden) {
      return;
    }

    if (isShowing && !isValidPath && !isOpen) {
      toggleLauncherVisibility(false);
      setIsShowing(false);
    } else if (!isShowing && isValidPath) {
      toggleLauncherVisibility(true);
      setIsShowing(true);
    }
  }, [isReady, pathname, isOpen, isShowing, shouldBeHidden]);
}
